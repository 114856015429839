import axios from 'axios';

const setBlockLangData = function ({ commit }, { language, id, value, key = null }) {
  commit('setBlockLangData', { language, id, value, key });
};

export default {
  setLanguage({ commit }, language) {
    commit('setLanguage', language);
  },
  createPage({ commit }, language = 'en-GB') {
    commit('setPage', {
      id: null,
      slug: '',
      state: 'live',
      pageData: {
        defaultLanguage: language,
        availableLanguages: [language],
        content: {
          schemaVersion: 2,
          blocks: {},
          langData: {
            [language]: {
              metadata: {},
            },
          },
          layout: [],
        },
        metadata: {},
      },
    });
  },
  loadPage({ commit }, { url, state }) {
    commit('setPageState', 'loading');
    commit('setError', null);
    commit('setPage', null);
    axios
      .get(url, {
        params: { state },
      })
      .then((res) => {
        commit('setPageState', 'loaded');
        commit('setPage', res.data);
      })
      .catch((error) => {
        commit('setPageState', 'error');
        commit('setError', error);
      });
  },
  unsetPage({ commit }) {
    commit('setPageState', 'unloading');
    commit('setPage', null);
  },
  setPage({ commit }, page) {
    commit('setPageState', 'loading');

    // Cope with PHP's json_encode saving empty objects as arrays
    if (Array.isArray(page.pageData.content.blocks) && page.pageData.content.blocks.length === 0) {
      page.pageData.content.blocks = {};
    }

    if ((page.pageData.content.schemaVersion || 1) !== 2) {
      throw new Error('Invalid content; this version of vue-page requires schemaVersion 2');
    }

    commit('setPage', page);
    commit('setPageState', 'loaded');
  },
  addRow({ commit }, data) {
    commit('setBlock', data);
    commit('addRow', data.id);
  },
  addRowBlock({ commit, state }, { index, blockId }) {
    const rowId = state.page.pageData.content.layout[index];
    const blocks = [...state.page.pageData.content.blocks[rowId].blocks, blockId];
    commit('setBlockData', { id: rowId, key: 'blocks', value: blocks });
  },
  setRow({ commit }, { row, index }) {
    commit('setRow', { index, rowId: row.id });
    commit('setBlock', { block: row });
  },
  deleteRowBlock({ commit, state }, { rowIndex, blockIndex }) {
    const rowId = state.page.pageData.content.layout[rowIndex];
    const blocks = [...state.page.pageData.content.blocks[rowId].blocks];
    blocks.splice(blockIndex, 1);
    commit('setBlockData', { id: rowId, key: 'blocks', value: blocks });
  },
  swapRowBlock({ commit, state }, { rowIndex, blockIndex }) {
    const rowBlockId = state.page.pageData.content.layout[rowIndex];
    const blocks = [...state.page.pageData.content.blocks[rowBlockId].blocks];
    if (blocks[blockIndex] === undefined || blocks[blockIndex + 1] === undefined) {
      return;
    }
    blocks.splice(blockIndex, 2, blocks[blockIndex + 1], blocks[blockIndex]);
    commit('setBlockData', { id: rowBlockId, key: 'blocks', value: blocks });
  },
  deleteRow({ commit, state, dispatch }, idx) {
    const rowId = state.page.pageData.content.layout[idx];
    const blockIds = [...(state.page.pageData.content.blocks[rowId]?.blocks || [])];
    commit('deleteRow', idx);
    blockIds.forEach((blockId) => {
      dispatch('deleteBlock', blockId);
    });
  },
  swapRow({ commit, state }, index) {
    const rows = state.page.pageData.content.layout;
    if (rows[index] === undefined || rows[index + 1] === undefined) {
      return;
    }
    commit('swapRow', index);
  },
  setBlock({ commit }, block) {
    commit('setBlock', block);
  },
  setBlockData({ commit }, { id, key, value }) {
    commit('setBlockData', { id, key, value });
  },
  setBlockLangData,
  setRowLangData: setBlockLangData,
  deleteBlock({ commit, state, dispatch }, blockId) {
    // Does the block have children? Delete them first.
    const block = state.page.pageData.content.blocks[blockId];
    if (block?.blocks) {
      block.blocks.forEach((childId) => {
        dispatch('deleteBlock', childId);
      });
    }
    // WFP only
    if (block?.videos) {
      block.videos.forEach((childId) => {
        dispatch('deleteBlock', childId);
      });
    }

    commit('deleteBlock', blockId);
    state.page.pageData.availableLanguages.forEach((language) => {
      if (Object.hasOwn(state.page.pageData.content.langData[language], blockId)) {
        commit('deleteLangData', { language, blockId });
      }
    });
  },
  setSlug({ commit }, slug) {
    commit('setSlug', slug);
  },
  setMetadata({ commit }, { key = null, value }) {
    commit('setMetadata', { key, value });
  },
};
