import { createStore } from 'vuex';

import Article from 'src/store/article';
import App from 'src/store/app';
import TuiPage from 'src/store/tui-page';

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default function (/* { ssrContext } */) {
  const Store = createStore({
    modules: {
      Article,
      App,
      TuiPage,
    },

    strict: process.env.DEV,
  });

  return Store;
}
