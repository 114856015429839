export function isFirstRun(state) {
  return !state.profile?.firstRunCompletedAt;
}

export function userHasAdminRole(state) {
  return !state.profile.roles.includes('ROLE_ADMIN');
}

export function getUserName(state) {
  return state.profile?.name || '(name)';
}
