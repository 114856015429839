const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: 'home',
        path: '',
        component: () => import('pages/Home.vue'),
      },
      {
        name: 'article',
        path: 'articles/:slug',
        component: () => import('pages/Article.vue'),
      },
      {
        name: 'search',
        path: 'search',
        component: () => import('pages/Search.vue'),
      },
    ],
  },
];

// Always leave this as last one
routes.push({
  path: '/:catchAll(.*)*',
  component: () => import('pages/Error404.vue'),
});

export default routes;
