<template>
  <form :class="$style.searchBox" @submit.prevent="search">
    <div class="container" v-show="navSearch">
      <div :class="$style.label">Search for resources</div>

      <div :class="$style.row">
        <HomeSearch :class="$style.searchInput" @esc="setNavSearch(false)" />

        <button
          :class="$style.navButton"
          @click.prevent="setNavSearch(false)"
          aria-label="Hide search"
        >
          <img
            src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48'%3E %3Cg fill='%23fff'%3E %3Cpath data-name='Path 49' d='M25.4 24l10.3-10.3a.99.99 0 10-1.4-1.4L24 22.6 13.7 12.3a.99.99 0 00-1.4 1.4L22.6 24 12.3 34.3a.97.97 0 000 1.4.97.97 0 001.4 0L24 25.4l10.3 10.3a.97.97 0 001.4 0 .97.97 0 000-1.4z'/%3E %3Cpath data-name='Path 50' d='M24 2A22 22 0 112 24 22.07 22.07 0 0124 2m0-2a24 24 0 1024 24A23.94 23.94 0 0024 0z'/%3E %3C/g%3E %3C/svg%3E"
            alt=""
          />
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import HomeSearch from 'src/components/HomeSearch.vue';

export default {
  data() {
    return {
      q: '',
    };
  },
  computed: {
    ...mapState('App', ['navSearch']),
  },
  watch: {
    $route() {
      this.q = '';
      this.setNavSearch(false);
    },
  },
  methods: {
    ...mapActions('App', ['setNavSearch']),
    search() {
      this.$router
        .push(
          this.$route.name === 'search'
            ? { params: { q: this.q } }
            : { name: 'search', params: { q: this.q } },
        )
        .catch(() => {});
    },
  },
  components: {
    HomeSearch,
  },
};
</script>

<style lang="scss" module>
.input {
  background: transparent;
  flex: 1 1 auto;
  border: none;
  font-size: 18px;
  padding-bottom: 4px;
}

.navButton {
  appearance: none;
  background: transparent;
  border: none;
  font-size: 1.4rem;
}

.button {
  flex: 0 0 2rem;
  appearance: none;
  background: transparent;
  border: none;
  font-size: 1.4rem;
}

.label {
  display: block;
  margin-bottom: 16px;
  color: var(--theme-navsearch-label);
  @include typestyle-subsubheader-regular;
}

.searchBox {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--theme-navsearch-background);
  padding-top: 62px;
  padding-bottom: 42px;
}

.searchInput {
  max-width: 832px;
  flex: 1 1 auto;
  margin-right: 24px;
}

.row {
  width: 100%;
  display: flex;
}
</style>
