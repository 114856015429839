<template>
  <form
    @submit.prevent="search"
    method="GET"
    :action="$router.resolve({ to: 'search' })"
    :class="$style.searchForm"
  >
    <transition name="fade" appear>
      <label v-show="!searchQ.length" :class="$style.formLabel" for="HomeSearchInput">
        Search for resources
      </label>
    </transition>

    <input
      :class="$style.formInput"
      id="HomeSearchInput"
      type="text"
      v-model="searchQ"
      @keyup.esc="$emit('esc')"
    />

    <button :class="$style.formButton" type="submit" aria-label="Submit search">
      <img
        src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='27' viewBox='0 0 27 27'%3E %3Cpath d='M37.707,36.293l-8.969-8.969a10.016,10.016,0,1,0-1.414,1.414l8.969,8.969a1,1,0,0,0,1.414-1.414ZM21,29a8,8,0,1,1,8-8A8.009,8.009,0,0,1,21,29Z' transform='translate(-11 -11)'/%3E %3C/svg%3E"
      />
    </button>
  </form>
</template>

<script>
export default {
  data() {
    return {
      searchQ: '',
    };
  },
  methods: {
    search() {
      this.$router.push({ name: 'search', query: { q: this.searchQ } });
    },
  },
  emits: ['esc'],
};
</script>

<style lang="scss" module>
.searchForm {
  display: block;
  position: relative;
  background: var(--theme-homesearch-background);
  min-height: 3rem;
  border-bottom: 4px solid var(--theme-homesearch-border);
}

.formInput {
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 44px;
  bottom: 0;
  left: 0;
  padding: 8px 14px;
  width: calc(100% - 44px);
  height: 100%;
  appearance: none;
  border: 0;
  background: transparent;
}

.formLabel {
  display: block;
  padding: 8px 14px;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  appearance: none;
  background: transparent;
  color: var(--theme-homesearch-label);
}

.formButton {
  cursor: pointer;
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 4px;
  bottom: 0;
  width: 40px;
  appearance: none;
  background: transparent;
  color: var(--theme-homesearch-label);
  border: none;
}
</style>
