export default {
  setLanguage(state, language) {
    state.language = language;
  },
  setPage(state, page) {
    state.page = page;
  },
  setPageState(state, pageState) {
    state.pageState = pageState;
  },
  addRow(state, rowId) {
    state.page.pageData.content.layout.push(rowId);
  },
  setRow(state, { index, rowId }) {
    state.page.pageData.content.layout[index] = rowId;
  },
  deleteRow(state, index) {
    const rowBlockId = state.page.pageData.content.layout[index];
    delete state.page.pageData.content.blocks[rowBlockId];
    state.page.pageData.content.layout.splice(index, 1);
  },
  swapRow(state, index) {
    const rows = state.page.pageData.content.layout;
    rows.splice(index, 2, rows[index + 1], rows[index]);
  },
  setBlock(state, block) {
    state.page.pageData.content.blocks[block.id] = block;
  },
  setBlockData(state, { id, key, value }) {
    state.page.pageData.content.blocks[id][key] = value;
  },
  deleteBlock(state, blockId) {
    delete state.page.pageData.content.blocks[blockId];
    state.page.pageData.availableLanguages.forEach((language) => {
      delete state.page.pageData.content.langData[language][blockId];
    });
  },
  setBlockLangData(state, { language, id, value, key = null }) {
    // Create the object with an individual property
    if (key && !Object.hasOwn(state.page.pageData.content.langData[language], id)) {
      state.page.pageData.content.langData[language][id] = {
        [key]: value,
      };
      return;
    }

    // Set an individual property on an existing object
    if (key) {
      state.page.pageData.content.langData[language][id][key] = value;
      return;
    }

    // Set whole object
    state.page.pageData.content.langData[language][id] = value;
  },
  deleteLangData(state, { language, blockId }) {
    delete state.page.pageData.content.langData[language][blockId];
  },
  setMetadata(state, { value, key = null }) {
    // Set individual property
    if (key) {
      state.page.pageData.metadata[key] = value;
      return;
    }

    // Set whole object
    state.page.pageData.metadata = value;
  },
  setSlug(state, slug) {
    state.page.slug = slug;
  },
  setError(state, error) {
    state.error = error;
  },
};
