export default function () {
  return {
    serverMessage: null,
    profile: {
      id: null,
      firstRunCompletedAt: true,
      roles: [],
      storageSecret: null,
      name: 'Your account',
      analyticsConsent: 'unknown',
    },
    theme: 'default',
    navTitle: '',
    navSearch: false,
    articleLikes: [],
    mercureTopicPrefix: '',
  };
}
