import { createRouter, createWebHistory } from 'vue-router';

import routes from 'src/router/routes';

import { bus } from 'src/boot/bus';

const scrollBehavior = function (to) {
  const useReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

  if (to.params.y) {
    return new Promise((resolve) => {
      bus.once('trigger-scroll', () => {
        resolve({ top: to.params.y, behavior: useReducedMotion ? 'auto' : 'smooth' });
      });
    });
  }

  if (to.hash) {
    return new Promise((resolve) => {
      bus.once('trigger-scroll', () => {
        resolve({ el: to.hash, behavior: useReducedMotion ? 'auto' : 'smooth' });
      });
    });
  }

  return { top: 0, behavior: useReducedMotion ? 'auto' : 'smooth' };
};

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default function () {
  const Router = createRouter({
    scrollBehavior,
    routes,

    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE,
    history: createWebHistory(process.env.VUE_ROUTER_BASE),
  });

  return Router;
}
