export default {
  namespaced: false,
  state() {
    return {
      language: 'en_GB',
      relatedArticles: null,
      hoveredBlock: null,
    };
  },
  mutations: {
    setRelatedArticles(state, relatedArticles) {
      state.relatedArticles = relatedArticles;
    },
    hoveredBlockChanged(state, blockId) {
      state.hoveredBlock = blockId;
    },
  },
  actions: {
    relatedArticlesFetched({ commit }, relatedArticles) {
      commit('setRelatedArticles', relatedArticles);
    },
    clearRelatedArticles({ commit }) {
      commit('setRelatedArticles', null);
    },
    hoveredBlockChanged({ commit }, blockId) {
      commit('hoveredBlockChanged', blockId);
    },
  },
  getters: {
    getRelatedArticles(state) {
      return state.relatedArticles;
    },
    getUserLanguage(state) {
      return state.language;
    },
    getHoveredBlock(state) {
      return state.hoveredBlock;
    },
  },
};
