export default {
  getLanguage(state) {
    if (!state.page) {
      return state.language;
    }

    // If the current page does not support the selected language, fall back to default
    if (state.page.pageData.availableLanguages.indexOf(state.language) === -1) {
      return state.page.pageData.defaultLanguage;
    }

    return state.language;
  },
  translatedRows(state, getters) {
    if (!state.page) {
      return [];
    }

    /* Build an array of row blocks, filtering out rows that have a "languages"
     * prop that doesn't include the current language
     */
    const rows = state.page.pageData.content.layout
      .filter((rowId) => {
        const row = state.page.pageData.content.blocks[rowId];
        return Object.hasOwn(row, 'languages') ? row.languages.includes(getters.getLanguage) : true;
      })
      .map((rowId) => state.page.pageData.content.blocks[rowId]);

    // If the layout row has a "blocks" property, filter out blocks which don't support the current language
    return rows.map((row) => ({
      ...row,
      ...(Object.hasOwn(row, 'blocks')
        ? {
            blocks: row.blocks.filter((blockId) =>
              (state.page.pageData.content.blocks[blockId]?.languages || []).includes(
                getters.getLanguage,
              ),
            ),
          }
        : {}),
    }));
  },
  translatedBlock(state, getters) {
    return (id) => {
      const content = state.page.pageData.content;
      const row = { ...content.blocks[id] };
      if (Object.hasOwn(content.langData[state.page.pageData.defaultLanguage], row.id)) {
        Object.assign(row, content.langData[state.page.pageData.defaultLanguage][row.id]);
      }
      if (Object.hasOwn(content.langData[getters.getLanguage], row.id)) {
        Object.assign(row, content.langData[getters.getLanguage][row.id]);
      }
      return row;
    };
  },
  translatedMetadata(state, getters) {
    if (!state.page) {
      return {};
    }
    // Don't use precalculated translatedMetadata, because it doesn't get updated by the cms
    const metadata = { ...state.page.pageData.metadata };
    Object.assign(
      metadata,
      state.page.pageData.content.langData[state.page.pageData.defaultLanguage].metadata,
    );

    if (
      Object.hasOwn(state.page.pageData.content.langData, getters.getLanguage) &&
      Object.hasOwn(state.page.pageData.content.langData[getters.getLanguage], 'metadata')
    ) {
      Object.assign(metadata, state.page.pageData.content.langData[getters.getLanguage].metadata);
    }

    return metadata;
  },
  translatedMetaInfo(_, getters) {
    // Return a subset of the translated metadata that can be consumed by vue-meta
    const info = {};
    const translatedMetadata = getters.translatedMetadata;

    ['title', 'htmlAttrs', 'bodyAttrs', 'meta', 'link', 'style', 'script', 'noscript'].forEach(
      (key) => {
        if (Object.hasOwn(translatedMetadata, key)) {
          info[key] = translatedMetadata[key];
        }
      },
    );

    return info;
  },
};
