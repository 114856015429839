import axios from 'axios';

export function init({ dispatch }) {
  dispatch('getMotd');
}

export function getMotd({ commit }) {
  axios.get('/api/motd').then(({ data }) => {
    commit('setServerMessage', data.message);
    commit('setMercureTopicPrefix', data.mercureTopicPrefix);
  });
}

export async function clearServerMessage({ commit }) {
  commit('setServerMessage', null);
}

export async function setAnalyticsConsent() {}

export function setTheme({ commit }, theme) {
  commit('setTheme', theme);
}

export function setNavTitle({ commit }, value) {
  commit('setNavTitle', value);
}

export function setNavSearch({ commit }, value) {
  commit('setNavSearch', value);
}

export function setProfile({ commit }, value) {
  commit('setProfile', value);
}
