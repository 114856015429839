<template>
  <div>
    <ServerMessage />

    <transition name="menu">
      <AppSearch v-if="$route.name !== 'home' && navSearch" />
    </transition>

    <router-view v-slot="{ Component }">
      <transition name="fade" appear>
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import AppSearch from 'src/components/global/AppSearch.vue';

export default {
  name: 'App',
  computed: {
    ...mapState('App', ['theme', 'navSearch']),
  },
  created() {
    this.$store.dispatch('App/init');
  },
  watch: {
    $route(val) {
      if (val.meta?.theme) {
        this.setTheme(val.meta?.theme);
      }

      this.setNavSearch(false);
    },
    theme(val, oldVal) {
      document.querySelector('body').classList.remove(`theme--${oldVal}`);
      document.querySelector('body').classList.add(`theme--${val}`);
    },
  },
  methods: {
    ...mapActions('App', ['setTheme', 'setNavSearch']),
  },
  components: {
    AppSearch,
  },
};
</script>

<style>
.q-body--prevent-scroll {
  position: unset !important;
}
</style>
