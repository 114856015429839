export function setServerMessage(state, value) {
  state.serverMessage = value;
}

export function setMercureTopicPrefix(state, value) {
  state.mercureTopicPrefix = value;
}

export function setProfile(state, value) {
  state.profile = value;
}

export function setTheme(state, value) {
  state.theme = value;
}

export function setNavTitle(state, value) {
  state.navTitle = value;
}

export function setNavSearch(state, value) {
  state.navSearch = value;
}

export function setArticleLikes(state, value) {
  state.articleLikes = value;
}

export function addArticleLike(state, blockId) {
  let block = state.articleLikes.find((l) => l.blockId === blockId);
  // Defensively create a fake block if it's not found
  if (!block) {
    block = {
      blockId,
      likedByUser: false,
      blockLikeCount: 0,
    };
    state.articleLikes.push(block);
  }
  block.likedByUser = true;
  block.blockLikeCount++;
}
export function removeArticleLike(state, blockId) {
  const block = state.articleLikes.find((l) => l.blockId === blockId);
  if (!block) {
    return;
  }
  block.likedByUser = false;
  block.blockLikeCount--;
}
