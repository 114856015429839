import getters from 'src/store/tui-page/getters';
import actions from 'src/store/tui-page/actions';
import mutations from 'src/store/tui-page/mutations';

const state = () => ({
  page: null,
  pageState: 'initialised',
  language: null,
  error: null,
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
